import React, { useState, useEffect, useRef } from 'react';
import './ChatComponent.css';
import axios from 'axios';
import { formatDistanceToNow, parseISO } from 'date-fns';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

// Function to generate a color from a hash of the name
const stringToColor = (str) => {
    const customColors = {
        'Lincoln': 'hsl(0, 100%, 30%)', // Dark Red
        'Ben': 'hsl(0, 0%, 30%)' // Dark Grey
    };

    if (customColors[str]) {
        return customColors[str];
    }

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const hue = Math.abs(hash % 360);
    const saturation = 60 + (hash % 20); // Vary saturation between 60% and 80%
    const lightness = 50 + (hash % 20); // Vary lightness between 50% and 70%
    
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

// Utility function to convert URLs in text to clickable links
const linkify = (text) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
};

const ChatComponent = ({ page }) => {
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');
    const [chats, setChats] = useState([]);
    const [error, setError] = useState('');
    
    const [showPicker1, setShowPicker1] = useState(false);
    const [pickerPosition1, setPickerPosition1] = useState({ top: 0, left: 0 });
    const pickerRef1 = useRef(null);

    const [showPicker2, setShowPicker2] = useState(false);
    const [pickerPosition2, setPickerPosition2] = useState({ top: 0, left: 0 });
    const pickerRef2 = useRef(null);

    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [reactions, setReactions] = useState({});
    const [pickerButtonClicked, setPickerButtonClicked] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef1.current && !pickerRef1.current.contains(event.target) && !pickerButtonClicked) {
                setShowPicker1(false);
            }
            if (pickerRef2.current && !pickerRef2.current.contains(event.target) && !pickerButtonClicked) {
                setShowPicker2(false);
            }
            setPickerButtonClicked(false);
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [pickerButtonClicked]);

    useEffect(() => {
        fetchChats();
    }, [page]);

    const fetchReactions = async (commentId) => {
        try {
            const response = await axios.get(`https://henrie-cruise-be-bep4ine36a-uc.a.run.app/reactions/${commentId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching reactions:', error);
            return [];
        }
    };
    
    const fetchChats = async () => {
        try {
            const response = await axios.get(`https://henrie-cruise-be-bep4ine36a-uc.a.run.app/chats`, {
                params: { page }
            });
            const sortedChats = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            
            const reactionsData = await Promise.all(sortedChats.map(async (chat) => {
                const reactions = await fetchReactions(chat.id);
                return { [chat.id]: reactions };
            }));
            
            const mergedReactions = Object.assign({}, ...reactionsData);
            setChats(sortedChats);
            setReactions(mergedReactions);
        } catch (error) {
            console.error('Error fetching chats:', error);
            setError('Error fetching chats');
        }
    };

    const handleReactionClick = async (commentId, emoji) => {
        try {
            await axios.post(`https://henrie-cruise-be-bep4ine36a-uc.a.run.app/reactions`, {
                comment_id: commentId,
                emoji
            });
    
            // Fetch the updated reactions from the server to avoid double incrementing
            const updatedReactions = await fetchReactions(commentId);
    
            // Update the reactions state with the server response
            setReactions(prevReactions => ({
                ...prevReactions,
                [commentId]: updatedReactions
            }));
        } catch (error) {
            console.error('Error handling reaction:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !comment) {
            setError('Name and comment are required');
            return;
        }
        const currentTimestamp = new Date().toISOString();
        try {
            const response = await axios.post(`https://henrie-cruise-be-bep4ine36a-uc.a.run.app/chats`, {
                name,
                comment,
                page,
                timestamp: currentTimestamp
            });
            setChats([{ ...response.data, timestamp: currentTimestamp }, ...chats]);
            setComment('');
            setError('');
        } catch (error) {
            console.error('Error submitting comment:', error);
            setError('Error submitting comment');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    const handleInputChange = (e) => {
        setComment(e.target.value);
        setShowPicker1(false);
        setShowPicker2(false);
    };

    const handleFocus = () => {
        setShowPicker1(false);
        setShowPicker2(false);
    };

    const addEmoji = (emoji, pickerId = null) => {
        if (selectedCommentId) {
            handleReactionClick(selectedCommentId, emoji.native);
        } else {
            setComment(comment + emoji.native);
        }
        if (pickerId === 1) {
            setShowPicker1(false);
        } else if (pickerId === 2) {
            setShowPicker2(false);
        }
    };

    const formatTimestamp = (timestamp) => {
        const timeAgo = formatDistanceToNow(parseISO(timestamp), { addSuffix: true });

        if (timeAgo.includes('second')) {
            return 'just now';
        } else if (timeAgo.includes('minute')) {
            return timeAgo.replace('about ', '');
        } else if (timeAgo.includes('hour')) {
            return timeAgo.replace('about ', '');
        } else if (timeAgo.includes('day') && timeAgo.includes('1')) {
            return 'yesterday';
        } else if (timeAgo.includes('day')) {
            return timeAgo;
        } else {
            return timestamp; // Fallback to original timestamp
        }
    };

    const togglePicker = (pickerId, commentId = null, event = null) => {
        event.preventDefault(); // Prevent default button behavior
        event.stopPropagation(); // Stop event from bubbling up
    
        if (event) {
            const rect = event.target.getBoundingClientRect();
            const pickerHeight = 350; // Approximate height of the emoji picker
            const pickerWidth = 300; // Approximate width of the emoji picker
    
            // Position the bottom-left corner of the picker at the position of the button
            let top = rect.bottom + window.scrollY - pickerHeight;
            let left = rect.left + window.scrollX;
    
            // Adjust if the picker would go off the screen
            if (left + pickerWidth > window.innerWidth + window.scrollX) {
                left = rect.right + window.scrollX - pickerWidth;
            }
    
            top = Math.max(window.scrollY, top);
            left = Math.max(window.scrollX, left);
    
            if (pickerId === 1) {
                setPickerButtonClicked(true); // Prevent the handleClickOutside logic from running
                if (showPicker1) {
                    setShowPicker1(false);
                } else {
                    setPickerPosition1({ top, left });
                    setShowPicker1(true);
                }
            } else if (pickerId === 2) {
                setPickerButtonClicked(true); // Prevent the handleClickOutside logic from running
                if (showPicker2) {
                    setShowPicker2(false);
                } else {
                    setPickerPosition2({ top, left });
                    setShowPicker2(true);
                }
            }
        }
    
        setSelectedCommentId(commentId);
    };
    

    return (
        <div className="chat-component">
            <h3 className="chat-title">Chat</h3>
            <form onSubmit={handleSubmit} className="chat-form">
                <div className="form-group">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your Name"
                        className="chat-input"
                        required
                        onFocus={handleFocus}
                    />
                </div>
                <div className="form-group chat-input-group">
                    <input
                        type="text"
                        value={comment}
                        onChange={handleInputChange}
                        placeholder="Your Comment"
                        className="chat-input"
                        onKeyDown={handleKeyDown}
                        required
                        onFocus={handleFocus}
                    />
                    <button 
                        type="button" 
                        onClick={(event) => togglePicker(1, null, event)} 
                        className="emoji-button"
                    >
                        🙂
                    </button>
                    {showPicker1 && !selectedCommentId && (
                        <div 
                            ref={pickerRef1} 
                            style={{position: 'absolute',}}
                        >
                            <Picker data={data} onEmojiSelect={(emoji) => addEmoji(emoji, 1)} />
                        </div>
                    )}
                </div>
                <button type="submit" className="chat-submit-button">Send</button>
                {error && <p className="chat-error">{error}</p>}
            </form>
            <div className="chat-list">
                {chats.map((chat) => (
                    <div key={chat.id || chat.timestamp} className="chat-message">
                        <strong style={{ color: stringToColor(chat.name || 'Anonymous') }}>
                            {chat.name || 'Anonymous'}:
                        </strong>
                        <div
                            dangerouslySetInnerHTML={{ __html: linkify(chat.comment || 'No comment') }}
                        />
                        <small className="chat-timestamp">{formatTimestamp(chat.timestamp)}</small>
                        <div className="reactions-container">
                            <button
                                className="add-reaction"
                                onClick={(event) => togglePicker(2, chat.id, event)}
                            >
                                + 👍
                            </button>
                            {reactions[chat.id] && reactions[chat.id].map((reaction) => (
                                <span
                                    key={reaction.emoji}
                                    className="reaction"
                                    onClick={() => handleReactionClick(chat.id, reaction.emoji)}
                                >
                                    {reaction.emoji} {reaction.count}
                                </span>
                            ))}
                        </div>
                        {showPicker2 && selectedCommentId === chat.id && (
                            <div 
                                ref={pickerRef2} 
                                style={{position: 'relative',}}
                            >
                                <Picker data={data} onEmojiSelect={(emoji) => addEmoji(emoji, 2)} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChatComponent;
