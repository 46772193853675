import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';
import ChatComponent from '../ChatComponent';

const HomePage = () => {
  return (
    <div className='home'>
      <header className='home-header'>
        <h1>Hello Everyone! This is the Henrie Cruise Reunion Blog.</h1>
      </header>
      <main className='home-content'>
        <p>First of all, some information. The ship on which we will be cruising on is the Voyager of the Seas which among other things, has two pools, water slides, various free food (the main dining room, pizza, soft-serve ice cream and more), there are a few specialty restaurants though these are not free, two kids clubs (3 to 8 and 9 to 12), one teen club (13 to 17) and one nursery. Overall this ship has a wide variety of different entertainment and I think we'll all have a great cruise together.</p>
        <img src="/images/voyager-of-the-seas.png" className='image' alt="Ship" />
        <nav className='home-navigation'>
          <ul>
            <li><Link to="/ship">Ship</Link></li>
            <li><Link to="/ports">Ports</Link></li>
          </ul>
        </nav>
      </main>
      <ChatComponent page="/home" />

    </div>
  );
};

export default HomePage;
