import React from 'react';
import '../Ports'; // Adjusted import path
import '../Ports.css';
import ChatComponent from '../../ChatComponent';


const Barcelona = () => {
  return (
    <div className="barcelona-container">
      <h1>Day 1</h1>
      <h2>Valencia, Spain</h2>
      <div className="barcelona-content">
      <p className="section-title">Sights to see:</p>
      <p>
        <span className="attraction-title">La Lonja de la Seda:</span> A UNESCO World Heritage site, this beautiful Gothic building was originally a silk exchange. It’s an impressive piece of architecture and history.
      </p>
      <p>
        <span className="attraction-title">Valencia Cathedral:</span> This stunning cathedral is known for its mix of architectural styles and for housing the Holy Grail (according to legend). Climb the Miguelete Tower for great views of the city.
      </p>
      <p>
        <span className="attraction-title">Turia Gardens:</span> Once a riverbed, now a beautiful park that stretches through the city. It’s perfect for a walk, bike ride, or just relaxing.
      </p>
      
      <p className="section-title">Things to do:</p>
      <p>
        <span className="attraction-title">Boat Trips at Albufera Natural Park:</span> Take a boat ride on the lagoon at Albufera. It’s a peaceful experience and you can spot various birds and enjoy beautiful sunsets.
      </p>
      <p>
        <span className="attraction-title">Climb the Miguelete Tower:</span> Climb the steps of the Miguelete Tower at Valencia Cathedral for amazing views over the city.
      </p>
      
      <p>
        <span className="attraction-title">Remember:</span> Make sure to get back to the ship by 6:00 PM
      </p>
      <p>From 8:00 AM to 6:00 PM</p>
    </div>
      <img src="/images/valencia.png" className='image' />
      <ChatComponent page="/ports/valencia" />

    </div>
  );
};

export default Barcelona;
