import { Link, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import Barcelona from './Ports/Barcelona';
import Valencia from './Ports/Valencia';
import Seaday from './Ports/Seaday';
import Toulon from './Ports/Toulon';
import Nice from './Ports/Nice';
import Ajaccio from './Ports/Ajaccio';
import Livorno from './Ports/Livorno';
import Rome from './Ports/Rome';
import ChatComponent from '../ChatComponent';

function Ports() {
  const location = useLocation();

  return (
    <div className="ports-container">
      <header className="ports-header">
        <h1>Ports</h1>
      </header>
      <section className="ports-content">
        <nav className="ports-navigation">
          <Link to="barcelona">Boarding Day Barcelona</Link>
          <Routes>
            <Route path="barcelona" element={<Barcelona />} />
          </Routes>
        </nav>
        <nav className="ports-navigation">
          <Link to="valencia">Day 1 Valencia</Link>
          <Routes>
            <Route path="valencia" element={<Valencia />} />
          </Routes>
        </nav>
        <nav className="ports-navigation">
          <Link to="seaday">Day 2 Sea day</Link>
          <Routes>
            <Route path="seaday" element={<Seaday />} />
          </Routes>
        </nav>
        <nav className="ports-navigation">
          <Link to="toulon">Day 3 Toulon</Link>
          <Routes>
            <Route path="toulon" element={<Toulon />} />
          </Routes>
        </nav>
        <nav className="ports-navigation">
          <Link to="nice">Day 4 Nice</Link>
          <Routes>
            <Route path="nice" element={<Nice />} />
          </Routes>
        </nav>
        <nav className="ports-navigation">
          <Link to="ajaccio">Day 5 Ajaccio</Link>
          <Routes>
            <Route path="ajaccio" element={<Ajaccio />} />
          </Routes>
        </nav>
        <nav className="ports-navigation">
          <Link to="livorno">Day 6 Livorno</Link>
          <Routes>
            <Route path="livorno" element={<Livorno />} />
          </Routes>
        </nav>
        <nav className="ports-navigation">
          <Link to="rome">Day 7 Rome</Link>
          <Routes>
            <Route path="rome" element={<Rome />} />
          </Routes>
        </nav>
      </section>
      <Outlet />
      {/* Render ChatComponent only if the current path is exactly /ports */}
      {location.pathname === '/ports' && <ChatComponent page="/ports" />}
    </div>
  );
}

export default Ports;
