import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import { Link } from 'react-router-dom';

const backendUrl = 'https://henrie-cruise-be-bep4ine36a-uc.a.run.app';

const HeaderComponent = ({ targetDate }) => {
  const [timeRemaining, setTimeRemaining] = useState({});
  const [prevTimeRemaining, setPrevTimeRemaining] = useState({});

  useEffect(() => {
    const fetchCurrentTime = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/current-time`);
        const currentTimeUTC = new Date(response.data.currentTime);
        const initialTimeRemaining = getTimeRemaining(targetDate, currentTimeUTC);
        setTimeRemaining(initialTimeRemaining);

        // Start the countdown timer
        const timer = setInterval(() => {
          const newCurrentTime = new Date().getTime(); // Get current time in milliseconds
          const updatedTimeRemaining = getTimeRemaining(targetDate, newCurrentTime);
          setTimeRemaining(updatedTimeRemaining);
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(timer);
      } catch (error) {
        console.error('Error fetching current time:', error);
      }
    };

    fetchCurrentTime();
  }, [targetDate]);

  useEffect(() => {
    // Compare current and previous timeRemaining to detect changes
    checkAndAnimateChange('days');
    checkAndAnimateChange('hours');
    checkAndAnimateChange('minutes');
    checkAndAnimateChange('seconds');

    // Update previous timeRemaining
    setPrevTimeRemaining(timeRemaining);
  }, [timeRemaining]);

  function getTimeRemaining(endtime, currentTime) {
    // Parse target date as UTC
    const targetDateUTC = new Date(endtime);

    // Calculate the difference in milliseconds
    const total = targetDateUTC - currentTime;

    // Convert total milliseconds to days, hours, minutes, seconds
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return { total, days, hours, minutes, seconds };
  }

  function checkAndAnimateChange(type) {
    if (timeRemaining[type] !== prevTimeRemaining[type]) {
      triggerAnimation(type);
    }
  }

  function triggerAnimation(type) {
    const element = document.getElementById(`countdown-${type}`);
    if (element) {
      element.classList.add('animate-spin');

      // Remove the class after the animation completes
      element.addEventListener('animationend', () => {
        element.classList.remove('animate-spin');
      }, { once: true });
    }
  }

  return (
    <div className='header'>
      <div className='countdown-container'>
        <h1 className='countdown-text'>Countdown to Cruise Launch</h1>
        <h2 className='countdown'>
          <span className="countdown-item" id="countdown-days">{timeRemaining.days}</span> days{' '}
          <span className="countdown-item" id="countdown-hours">{timeRemaining.hours}</span> hours{' '}
          <span className="countdown-item" id="countdown-minutes">{timeRemaining.minutes}</span> minutes{' '}
          <span className="countdown-item" id="countdown-seconds">{timeRemaining.seconds}</span> seconds
        </h2>
      </div>
      <nav className='navigation'>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/ship">Ship</Link></li>
          <li><Link to="/ports">Ports</Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default HeaderComponent;
