import React from 'react';
import '../Ports'; // Adjusted import path
import '../Ports.css';
import ChatComponent from '../../ChatComponent';

const Barcelona = () => {
  return (
    <div className="barcelona-container">
      <h1>Day 5</h1>
      <h2>Ajaccio, Corsica (France)</h2>
      <div className="barcelona-content">
        <p>
          <span className="attraction-title">Sights to see:</span>
          <br />
          <span className="attraction-title">The Citadel:</span> You can walk around the exterior of this 16th-century fortress for a sense of Ajaccio’s military history and enjoy the coastal views.
          <br />
          <span className="attraction-title">Ajaccio Cathedral (Cathédrale Notre-Dame-de-l'Assomption):</span> This beautiful cathedral is where Napoleon was baptized. It's known for its baroque architecture and stunning interior.
          <br />
          <span className="attraction-title">Eglise Saint-Roch:</span> This charming church is worth a visit for its beautiful architecture and serene atmosphere. It’s located in the heart of Ajaccio.
        </p>
        <p>
          <span className="attraction-title">Things to do:</span>
          <br />
          <span className="attraction-title">Les Îles Sanguinaires:</span> Take a boat trip to these stunning islands near Ajaccio. They are famous for their dramatic red hues at sunset and offer great opportunities for hiking and bird watching.
          <br />
          <span className="attraction-title">Enjoy Water Sports:</span> Try kayaking, paddleboarding, or snorkeling in the clear waters of Ajaccio.
        </p>
        <p className="attraction-title">
          Remember to get back to the ship by 6:00 PM
        </p>
        <p>
          From 8:00 AM to 6:00 PM
        </p>
      </div>
      <img src="/images/ajaccio.png" className='image' />
      <ChatComponent page="/ports/ajaccio" />

    </div>
  );
};

export default Barcelona;
