import React, { useState, useEffect } from 'react';
import './PasswordProtect.css'; // Import the CSS file for styling

const PasswordProtect = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const correctPassword = 'voyager-24'; // Replace this with your actual password

    useEffect(() => {
        // Check if the user is already authenticated
        const authTime = localStorage.getItem('authTime');
        if (authTime && new Date().getTime() - authTime < 24 * 60 * 60 * 1000) {
            setIsAuthenticated(true);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword) {
            localStorage.setItem('authTime', new Date().getTime());
            setIsAuthenticated(true);
        } else {
            setError('Incorrect password. Please try again.');
        }
    };

    if (isAuthenticated) {
        return <>{children}</>;
    }

    return (
        <div className="password-container">
            <div className="password-box">
                <h2>Enter Password</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        className="password-input"
                        required
                    />
                    <button type="submit" className="password-submit-button">Submit</button>
                </form>
                {error && <p className="password-error">{error}</p>}
            </div>
        </div>
    );
};

export default PasswordProtect;
