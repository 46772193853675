import React from 'react';
import '../Ports'; // Adjusted import path
import '../Ports.css';
import ChatComponent from '../../ChatComponent';

const Barcelona = () => {
  return (
    <div className="barcelona-container">
      <h1>Day 6</h1>
      <h2>Pisa (Livorno), Italy</h2>
      <div className="barcelona-content">
        <p>
          <span className="attraction-title">Sights to see:</span>
          <br />
          <span className="attraction-title">Sanctuary of Montenero:</span> Take a trip to this hilltop sanctuary offering panoramic views of Livorno and the surrounding countryside.
          <br />
          <span className="attraction-title">Leaning Tower of Pisa:</span> This iconic, world-famous bell tower is a must-see. Climb to the top for a unique perspective of the city and surrounding area.
          <br />
          <span className="attraction-title">Camposanto Monumentale:</span> This historic cemetery is renowned for its frescoes and impressive marble tombs. It's a peaceful place with a rich history.
        </p>
        <p>
          <span className="attraction-title">Things to do:</span>
          <br />
          <span className="attraction-title">Explore Piazza dei Miracoli:</span> Spend time exploring this beautiful square. Visit the Cathedral, Baptistery, and Camposanto, and marvel at their stunning architecture.
          <br />
          <span className="attraction-title">Discover Pisa’s Botanical Garden:</span> One of Europe’s oldest botanical gardens, it's a great place to relax and enjoy a variety of plants and flowers.
        </p>
        <p className="attraction-title">
          Remember to get back to the ship by 7:00 PM
        </p>
        <p>
          From 7:00 AM to 7:00 PM
        </p>
      </div>
      <img src="/images/livorno.png" className='image' />
      <ChatComponent page="/ports/livorno" />

    </div>
  );
};

export default Barcelona;
