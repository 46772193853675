import React from 'react';
import '../Ports'; // Adjusted import path
import '../Ports.css';
import ChatComponent from '../../ChatComponent';

const Barcelona = () => {
  return (
    <div className="barcelona-container">
      <h1>Day 3</h1>
      <h2>Provence (Toulon), France</h2>
      <div className="barcelona-content">
        <p>
          <span className="attraction-title">Sights to see:</span>
          <br />
          <span className="attraction-title">Fort Saint-Louis:</span> Visit this historic fortification near Mourillon Beach. It’s an interesting site and offers good views of the coastline.
          <br />
          <span className="attraction-title">Jardin Alexandre I:</span> A beautiful park in the city center with lovely gardens, fountains, and a playground. It's a perfect spot for a relaxing walk.
          <br />
          <span className="attraction-title">Nearby Islands:</span> Take a day trip to the Îles d'Hyères (Porquerolles, Port-Cros, and Île du Levant). These islands are known for their natural beauty and excellent hiking and biking trails.
        </p>
        <p>
          <span className="attraction-title">Things to do:</span>
          <br />
          <span className="attraction-title">Take the Cable Car to Mont Faron:</span> Ride the Téléphérique du Faron for breathtaking views of the city and the Mediterranean Sea and enjoy hiking trails.
          <br />
          <span className="attraction-title">Stroll Along the Port of Toulon:</span> Enjoy the bustling atmosphere of Toulon's waterfront, with plenty of restaurants, shops, and boats to watch. You can also take a boat tour of the harbor.
        </p>
        <p className='attraction-title'>
          Remember to get back to the ship by 6:00 PM
        </p>
        <p>
          From 8:00 AM to 6:00 PM
        </p>
      </div>
      <img src="/images/toulon.png" className='image' />
      <ChatComponent page="/ports/toulon" />

    </div>
  );
};

export default Barcelona;
