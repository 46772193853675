import React from 'react';
import '../Ports'; // Adjusted import path
import '../Ports.css';
import ChatComponent from '../../ChatComponent';

const Barcelona = () => {
  return (
    <div className="barcelona-container">
      <h1>Day 4</h1>
      <h2>Nice, France</h2>
      <div className="barcelona-content">
        <p>
          <span className="attraction-title">Sights to see:</span>
          <br />
          <span className="attraction-title">Nice Cathedral:</span> Located in the old town, this beautiful Baroque cathedral is worth a visit for its stunning interior and historical significance.
          <br />
          <span className="attraction-title">Russian Orthodox Cathedral:</span> An unexpected sight in Nice, this stunning cathedral is the largest Russian Orthodox church in Western Europe. Its architecture and interior are impressive.
          <br />
          <span className="attraction-title">Promenade des Anglais:</span> This famous seaside promenade stretches along the Mediterranean coast. It's perfect for a leisurely walk, bike ride, or just enjoying the view.
        </p>
        <p>
          <span className="attraction-title">Things to do:</span>
          <br />
          <span className="attraction-title">Visit Castle Hill:</span> Climb up to Castle Hill for stunning panoramic views of Nice and the sea. There’s a park, a waterfall, and ruins of the old castle. You can either hike up or take the elevator.
          <br />
          <span className="attraction-title">Go Paragliding:</span> For an adrenaline rush, try paragliding over the Mediterranean. The views from above are unforgettable.
        </p>
        <p className="attraction-title">
          This day is tendered, so rather than docking, they will take passengers to shore in ferries.
        </p>
        <p>
          Tendered from 7:00 AM to 8:00 PM
        </p>
      </div>
      <img src="/images/nice.png" className='image' />
      <ChatComponent page="/ports/nice" />

    </div>
  );
};

export default Barcelona;
