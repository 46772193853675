import React from 'react';
import '../Ports'; // Adjusted import path
import '../Ports.css';
import { Link } from 'react-router-dom';
import ChatComponent from '../../ChatComponent';

const Barcelona = () => {
  return (
    <div className="barcelona-container">
      <h1>Day 2</h1>
      <h2>Sea Day!</h2>
      <div className="barcelona-content">
        <p>
          <span className="attraction-title">Believe it or not:</span> Sea days are actually my favorite. If you want to know some things to do on the ship just <Link to="/ship" className="text-link">click here</Link> or if you want some ideas for what to do, here are some of my personal favorites:
        </p>
        <p>
          <span className="attraction-title">Eat:</span> This might be my favorite thing to do on a cruise ship. Check out the Wind Jammer if you want a large variety of food or on sea days the Main Dining is open for lunch as well as breakfast and dinner.
        </p>
        <p>
          <span className="attraction-title">Kids Clubs:</span> Never a dull moment here; kids from 3 to 12 and teens from 13 to 17 can have fun playing group games or just hanging out.
        </p>
        <p>
          <span className="attraction-title">Swimsuit Stuff:</span> (Say that ten times fast) Head over to the pools, hot tubs, water slides, and the FlowRider. These activities are all very fun and quite wet. Be aware the water slides and FlowRider have a minimum height.
        </p>
        <p className='attraction-title'> All day</p>
      </div>
      <ChatComponent page="/ports/seaday" />

    </div>
  );
};

export default Barcelona;
