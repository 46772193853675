import React from 'react';
import '../Ports'; // Adjusted import path
import '../Ports.css';
import ChatComponent from '../../ChatComponent';

const Barcelona = () => {
  return (
    <div className="barcelona-container">
      <h1>Day 7</h1>
      <h2>Rome, Italy</h2>
      <div className="barcelona-content">
        <p>
          <span className="attraction-title">Sights to see:</span>
          <br />
          <span className="attraction-title">Colosseum:</span> This iconic ancient amphitheater is one of Rome's most famous landmarks. Explore the massive structure and imagine the gladiatorial contests that once took place here.
          <br />
          <span className="attraction-title">Pantheon:</span> Visit this well-preserved ancient Roman temple, now a church, known for its massive dome and oculus. The Pantheon’s architecture is awe-inspiring.
          <br />
          <span className="attraction-title">Trevi Fountain:</span> Toss a coin into this stunning Baroque fountain to ensure your return to Rome.
        </p>
        <p>
          <span className="attraction-title">Things to do:</span>
          <br />
          <span className="attraction-title">Visit the Vatican Museums:</span> Explore the vast collection of art and artifacts in the Vatican Museums, including masterpieces by Michelangelo, Raphael, and Leonardo da Vinci. Make sure to check out the ceiling of the Sistine Chapel.
          <br />
          <span className="attraction-title">Take a Walk Along the Tiber River:</span> Enjoy a leisurely stroll along the banks of the Tiber River, taking in views of historic bridges like the Ponte Sant'Angelo and the picturesque riverside scenery.
        </p>
        <p className="attraction-title">
          This is the end of the cruise so get to the airport on time.
        </p>
        <p>
          Arrives at 5:00 AM
        </p>
      </div>
      <img src="/images/rome.png" className='image' />
      <ChatComponent page="/ports/rome" />

    </div>
  );
};

export default Barcelona;
