import React from 'react';
import '../Ports'; // Adjusted import path
import '../Ports.css';
import ChatComponent from '../../ChatComponent';


const Barcelona = () => {
  return (
    <div className="barcelona-content">
      <p className="section-title">Sights to see:</p>
      <p>
        <span className="attraction-title">Sagrada Familia:</span> This basilica’s unique architecture makes it a must see.
      </p>
      <p>
        <span className="attraction-title">Park Güell:</span> Another Gaudí masterpiece, this park is full of colorful mosaics and cool sculptures. Plus, it offers some great views of the city.
      </p>
      <p>
        <span className="attraction-title">La Rambla:</span> This bustling street is perfect for a stroll. You’ll find street performers, shops, and great places to grab a bite.
      </p>
      
      <p className="section-title">Things to do:</p>
      <p>
        <span className="attraction-title">Bike Tours:</span> Rent a bike or join a guided tour to explore the city. It's a fun and active way to see the sights.
      </p>
      <p>
        <span className="attraction-title">Kayaking and Paddleboarding:</span> Head to Barceloneta Beach for some water sports. Kayaking and paddleboarding are great ways to enjoy the sea.
      </p>
      
      <p className="attraction-title">Make sure to get to the ship before 5:00 PM</p>
      <p>Departs at 5:00 PM</p>
      <img src="/images/barcelona.png" className='image' />
      <ChatComponent page="/ports/barcelona" />

    </div>
  );
};

export default Barcelona;
