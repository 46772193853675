import React from 'react';
import './Ports'; // Adjusted import path
import './Ports.css';
import ChatComponent from '../ChatComponent';

const Ship = () => {
  return (
    <div className="barcelona-container ports-container barcelona-content">
      <h1>The Ship</h1>
        <p>
          Our ship The Voyager of the Seas from Its maiden voyage in 1999 to the present has dazzled guests with Its many attractions.
        </p>
        <div className='image-container'>
          <img src="/images/voyagerExterior.jpg" className='image' />
        </div>
        <p>
          This ship hit many new milestones and was the birthplace of many modern cruise amenities including the promenade which is your main Shopping and entertainment area. Walking along the promenade you come across many venues including the Pig and Whistle English pub where you can listen to live music or get some fish and chips, the Cafe Promenade for your pizza and other refreshments, the Drenched Surf Shop for your Royal Caribbean branded souvenirs and more.
        </p>
        <div className='image-container'>
          <img src="/images/promenade.png" className='image' />
        </div>
        <p>
          At the front of the ship is the Star Lounge, a main entertainment venue featuring karaoke, live music, trivia, and more, while customer service and excursions are located in the middle of deck five for planning activities or addressing issues like a broken room key.
        </p>
        <p>
        The largest venue on the Voyager of the Seas is the Royal Theater, located on decks three and four, where you can see standup comedy, Broadway shows, and even movies; the Main Dining Room, open for breakfast, lunch, and dinner on sea days, is located on decks three, four, and five and features a different three-course menu every night, offering a variety of dishes from many different cuisines and cultures.
        </p>
        <div className='image-container'>
          <img src="/images/mainDiningRoom.png" className='image' />
        </div>
        <p>
        Up on deck eleven, you’ll find the Wind Jammer buffet, open three meals a day every day of your cruise, and next to it is Chops Grille, the specialty steakhouse (WARNING: this restaurant is not free). 
        </p>
        <div className='image-container'>
          <img src="/images/windjammer.png" className='image' />
          <img src="/images/chops.png" className='image' />
        </div>
        <p>
        On deck seven is the library and card room, where you can check out a book or play card games and board games; on deck fourteen is Izumi Japanese Cuisine, the specialty sushi bar (WARNING: also not free), and on deck four is the specialty Italian restaurant Giovanni’s Table, where you can eat pasta, pizza, soup, and more (WARNING: yeah, another one).
        </p>
        <div className='image-container'>
          <img src="/images/sushi.png" className='image' />
          <img src="/images/giovannis.png" className='image' />
        </div>
        <p>
        Also on deck four is the art gallery, where you can see art before it is auctioned off, and on deck three, you have Studio B, which features all kinds of shows on ice (the ice rink is open to cruisers on some days), making this ship the first cruise ship ever to have an ice rink; Studio B also offers games like laser tag. 
        </p>
        <div className='image-container'>
          <img src="/images/ice.png" className='image' />
        </div>
        <p>
        Outside Studio B is the Focus Photo Gallery, where you can get information about photo packages for your cruise and last-minute cameras and accessories; deck eleven is primarily where the pool deck is located, featuring several pools and hot tubs, and near the Wind Jammer on the pool deck is the soft serve, which you’ll want to check out; just a warning: do not try to reserve pool deck chairs by leaving your bag on them, as they will take your stuff to the towel stand, which acts as the lost and found on the ship.
        </p>
        <div className='image-container'>
          <img src="/images/instax.png" className='image' />
        </div>
        <p>
        On deck eleven at the front of the ship is the Solarium, the adults-only pool and lounge where your parents can hang out while the kids are at the kids club; heading up to deck twelve, you’ll find pool chairs, the jogging track, and a great view of both the pool deck and the sea.
        </p>
        <div className='image-container'>
          <img src="/images/sea.png" className='image' />
        </div>
        <p>
        Located on deck thirteen is the sport court area, featuring the basketball court, rock climbing wall, Perfect Storm water slides, FlowRider, and mini-golf; another random bit of trivia is that when the Voyager of the Seas first launched, there was a roller skating track, but it was removed to add the FlowRider and water slides.
        </p>
        <div className='image-container'>
          <img src="/images/flowRider.png" className='image' />
          <img src="/images/slide.png" className='image' />
        </div>
        <p>
        Right near the FlowRider is the entrance to the Living Room, the teens-only area on the Voyager intended for ages thirteen to seventeen, which, much like the kids clubs, serves as a hangout space with group games and other fun activities; speaking of kids clubs, the Adventure Ocean on deck twelve is the kids club on the Voyager, featuring different age groups to maximize fun for everyone and functioning as a hangout and group event area.
        </p>
        <div className='image-container'>
          <img src="/images/teenClub.png" className='image' />
          <img src="/images/kidsClub.png" className='image' />
        </div>
        <p>
        On deck four, there is an outdoor walking area and a shuffleboard court, making it a great place for ship spotting, looking for turtles and dolphins, or just going for a stroll; on either side of the ship from the deck four walkway, you can take stairs to the helicopter pad, one of the best places to watch the cast off or just hang out and talk, but be warned, it can get very windy, so make sure to wear a coat or, even better, bring some hot chocolate.
        </p>
        <div className='image-container'>
          <img src="/images/hotChocolate.png" className='image' />
        </div>
        <p>
        On deck twelve near Adventure Ocean is the Challengers’ Arcade, where you can play skee ball, air hockey, or try your luck at a claw machine; also on deck twelve is the Vitality at Sea Spa and Fitness Center, which houses the spa and gym; all the way down on deck two is the Conference Center, where you can host business meetings and similar events; another bit of trivia: the windows above the entrance to the Conference Center once led to a nightclub, but now they overlook some rooms.
        </p>
        <div className='image-container'>
          <img src="/images/arcade.png" className='image' />
        </div>
        <p>
        These are just a few highlights of the many amazing activities on the Voyager of the Seas; well, that’s just about it! If you have a question or comment, I’ll do my best to respond. Sea you soon!;)
        </p>
      <ChatComponent page="/ship" />

    </div>
  );
};

export default Ship;
