import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeaderComponent from './HeaderComponent';
import Home from './Pages/Home';
import Ship from './Pages/Ship';
import Ports from './Pages/Ports';
import PasswordProtect from './PasswordProtect';
import ReactGA from 'react-ga';

const targetDate = new Date('2024-09-30T17:00:00');

const TRACKING_ID = "G-1VFKVPNTYQ";
ReactGA.initialize(TRACKING_ID);

const App = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <PasswordProtect>
            <Router>
                <div>
                    <HeaderComponent targetDate={targetDate} />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/ship" element={<Ship />} />
                        <Route path="/ports/*" element={<Ports />} />
                    </Routes>
                </div>
            </Router>
        </PasswordProtect>
    );
};

export default App;
